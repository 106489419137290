import * as ko from 'knockout';
import page from 'page';

import i18n from '../i18n';
import { session } from '../session';
import * as trialsApi from '../api/trials';
import * as exporterApi from '../api/exporter';
import * as dashboardApi from '../api/dashboard';
import * as dimensionsApi from '../api/dimensions';
import { translate } from '../i18n_text';
import { downloadBlob, indexOf } from '../utils';
import { BaseLoadingScreen } from './base_loading_screen';
import { SlugGenerator } from '../ko_bindings/slug_validation';
import { pollTask } from '../api/tasks';
import { NormalizationSelectConfig } from '../components/normalization_select';
import * as datasetsApi from '../api/datasets';
import { FilterDelegate } from '../components/list_filters';
import { DimensionData } from '../api/dimensions';
import { ScheduledVisitData } from '../api/scheduled_visits';
import { MeasurementMetaData } from '../api/v2/interfaces';
import { deflateList } from '../api/serialization';

let template = require('raw-loader!../../templates/trial_export.html').default;

interface MesOption {
  key: string;
  id: string;
  name: string;
  dataset_id: string;
  type: string;
  is_date: boolean;
  is_surface: boolean;
  contains_management_data: boolean;
  is_trait: boolean;
  default_aggregation: string;
}

function makeMesOption(title: string, slug: string): MesOption {
  return {
    key: slug,
    id: slug,
    name: title,
    dataset_id: null,
    type: '',
    is_date: false,
    is_surface: false,
    contains_management_data: false,
    is_trait: false,
    default_aggregation: 'mean',
  };
}

class TrialExportScreen extends BaseLoadingScreen {
  private trial: trialsApi.TrialData;

  preparingExportText = i18n.t('Preparing export...')();
  downloadCustomExportIcon = 'file_download';
  downloadCustomExportText = i18n.t('Export grouped by plot');

  loadingCompactExport = ko.observable(false);
  loadingCustomExport = ko.observable(false);
  loadingRawExport = ko.observable(false);

  exportingImagesText = ko.observable('');
  exportingImagesError = ko.observable('');

  calcOptions = [
    { name: 'X - Y', value: 'subtraction' },
    { name: 'X + Y', value: 'addition' },
    { name: 'X / Y', value: 'ratio' },
    { name: 'X * Y', value: 'multiplication' },
    { name: 'X / Y * 100', value: 'percentage' },
    { name: '100 - (X / Y * 100)', value: 'reverse_percentage' },
  ];
  mesOptions: MesOption[];
  aggregatedColumns = ko.observableArray<AggregatedColumn>();
  calculatedColumns = ko.observableArray<CalculatedColumn>();
  rememberCustomExportOptions = ko.observable(true);

  name = ko.observable('');
  template = ko.observable(false);
  showGlobalError = ko.observable(false);

  private sitesFilter = ko.observableArray<DimensionData>();
  private scheduledVisitFilter = ko.observableArray<ScheduledVisitData>();
  private traitsFilter = ko.observableArray<MeasurementMetaData>();
  private testSubjectsFilter = ko.observableArray<DimensionData>();
  private testSubjectsDmIds: string[] = [];

  sitesOptions = ko.observableArray<DimensionData>();
  scheduledVisitsOptions = ko.observableArray<ScheduledVisitData>();
  traitsOptions = ko.observableArray<MeasurementMetaData>();

  overviewFilters: FilterDelegate[] = [
    {
      title: i18n.t('Site')(),
      entities: this.sitesFilter,
      list: (params) => {
        let options = this.sitesOptions();
        if (params.name_prefix) {
          options = options.filter(
            (option) => translate(option.name_json).toLowerCase().indexOf(params.name_prefix) > -1
          );
        }
        return Promise.resolve(options);
      },
    },
    {
      title: i18n.t('Visit')(),
      entities: this.scheduledVisitFilter,
      list: (params) => {
        let options = this.scheduledVisitsOptions();
        if (params.name_prefix) {
          options = options.filter(
            (option) => translate(option.name_json).toLowerCase().indexOf(params.name_prefix) > -1
          );
        }
        return Promise.resolve(options);
      },
    },
    {
      title: i18n.t('Traits')(),
      entities: this.traitsFilter,
      list: (params) => {
        let options = this.traitsOptions().filter((option) =>
          ['picture', 'multi_pictures'].includes(option.type)
        );
        if (params.name_prefix) {
          options = options.filter((option) => option.name.toLowerCase().indexOf(params.name_prefix) > -1);
        }
        return Promise.resolve(options);
      },
    },
    {
      title: i18n.t(['test_subjects_title', 'Test subjects'])(),
      entities: this.testSubjectsFilter,
      list: (params) =>
        dimensionsApi.listForDMS(this.testSubjectsDmIds, { trial_id: this.trial.id }, params),
    },
  ];

  constructor(params: { id: string }) {
    super();

    let customExportPromise = exporterApi.customExportDefinition(params.id);
    let trialDataPromise = trialsApi.retrieve(params.id);
    let datasetsPromise = datasetsApi.list(params.id);

    let promise = Promise.all([customExportPromise, trialDataPromise, datasetsPromise]).then(
      ([customExportData, trialData, datasetsData]) => {
        this.mesOptions = customExportData.available_aggregate_mms.map((mes, idx) => {
          let name: string;
          if (mes.is_trait) {
            name =
              translate(mes.name_json) +
              ' (' +
              i18n.t('Trait')() +
              ', ' +
              translate(mes.dataset_name_json) +
              ')';
          } else {
            name = translate(mes.name_json) + ' (' + translate(mes.dataset_name_json) + ')';
          }
          return {
            key: idx.toString(),
            id: mes.id,
            name,
            dataset_id: mes.dataset_id,
            type: mes.type,
            is_date: mes.type === 'date',
            is_surface: mes.is_surface,
            contains_management_data: mes.contains_management_data,
            is_trait: mes.is_trait,
            default_aggregation: mes.default_aggregation,
          };
        });
        this.mesOptions.splice(0, 0, makeMesOption(i18n.t('Select')(), ''));

        this.trial = trialData;
        this.name(translate(trialData.name_json));
        this.template(trialData.template);

        let expData = customExportData.custom_export;
        if (expData.aggregated.length === 0 && expData.calculated.length === 0) {
          // by default, add all non-management measurements
          expData.aggregated = this.mesOptions
            .filter((opt) => !!opt.id && !opt.contains_management_data)
            .map((opt) => {
              return {
                id: null,
                mm_id: opt.id,
                dataset_id: opt.dataset_id,
                aggregation: 'mean', // note: will automatically change to 'latest' if mean not supported
                use_only_for_calculation: false,
                normalize: false,
                normalization_mm_id: null,
              };
            });
        }
        this.aggregatedColumns(
          expData.aggregated.map(
            (data) => new AggregatedColumn(this.mesOptions, this.aggregatedColumns, data)
          )
        );
        this.calculatedColumns(
          expData.calculated.map(
            (data) => new CalculatedColumn(this.mesOptions, this.aggregatedColumns, data)
          )
        );

        if (trialData.template) {
          this.preparingExportText = i18n.t('Saving export definition...')();
          this.downloadCustomExportIcon = 'save';
          this.downloadCustomExportText = i18n.t('Save');
        }

        // Extract test_subject_dm_ids from datasets
        const dimensionMetaIds = new Set<string>();
        for (const ds of datasetsData) {
          for (const dmId of ds.ts_dm_ids) {
            dimensionMetaIds.add(dmId);
          }
        }

        this.sitesOptions(trialData.sites);
        this.scheduledVisitsOptions(trialData.scheduled_visits);
        this.traitsOptions(trialData.traits);
        this.testSubjectsDmIds = Array.from(dimensionMetaIds);
      }
    );
    this.loadedAfter(promise);
  }

  dispose() {
    this.aggregatedColumns().forEach((col) => col.dispose());
    this.calculatedColumns().forEach((col) => col.dispose());

    if (this.exportImagesToken) {
      clearInterval(this.exportImagesToken);
      this.exportImagesToken = null;
    }
  }

  goBack = () => {
    history.back();
  };

  downloadCompactExport = () => {
    this.loadingCompactExport(true);

    dashboardApi
      .compactExport(this.trial.id)
      .then((data) => {
        this.loadingCompactExport(false);
        downloadBlob(data, translate(this.trial.name_json) + '.xlsx');
      })
      .catch((e) => {
        this.loadingCompactExport(false);
        throw e;
      });
  };

  addAggregatedColumn = () => {
    let col = new AggregatedColumn(this.mesOptions, this.aggregatedColumns);
    this.aggregatedColumns.push(col);
  };

  removeAggregatedColumn = (col: AggregatedColumn) => {
    col.dispose();
    this.aggregatedColumns.remove(col);
  };

  addCalcColumn = () => {
    let col = new CalculatedColumn(this.mesOptions, this.aggregatedColumns);
    col.calc('subtraction');
    this.calculatedColumns.push(col);
  };

  removeCalcColumn = (col: CalculatedColumn) => {
    col.dispose();
    this.calculatedColumns.remove(col);
  };

  hasError = ko.pureComputed(
    () => this.showGlobalError() && (this.getValidationGroup()().length > 0 || this.hasAggError())
  );

  private hasAggError() {
    return this.calculatedColumns().some((calc) => !!calc.aggError());
  }

  private getValidationGroup() {
    return ko.validation.group([this.aggregatedColumns(), this.calculatedColumns()], { deep: true });
  }

  downloadCustomExport = () => {
    this.customExport('plot');
  };

  downloadCustomExportBySite = () => {
    this.customExport('site');
  };

  private customExport(grouping: exporterApi.CustomExportGrouping) {
    let group = this.getValidationGroup();
    if (group().length > 0 || this.hasAggError()) {
      this.showGlobalError(true);
      group.showAllMessages();
      return;
    } else {
      this.showGlobalError(false);
    }

    let data = {
      trial_id: this.trial.id,
      remember: this.rememberCustomExportOptions(),
      aggregated: this.aggregatedColumns().map((col) => col.toData()),
      calculated: this.calculatedColumns().map((col) => col.toData()),
      grouping,
    };

    this.loadingCustomExport(true);
    if (this.template()) {
      exporterApi
        .saveCustomExport(data)
        .then((data) => {
          this.loadingCustomExport(false);
          page(session.toTenantPath('/trial_templates/'));
        })
        .catch((e) => {
          this.loadingCustomExport(false);
          throw e;
        });
    } else {
      exporterApi
        .customExport(data)
        .then((data) => {
          this.loadingCustomExport(false);
          downloadBlob(data, translate(this.trial.name_json) + '.xlsx');
        })
        .catch((e) => {
          this.loadingCustomExport(false);
          throw e;
        });
    }
  }

  downloadRawExport = () => {
    this.loadingRawExport(true);

    exporterApi
      .rawExport(this.trial.id)
      .then((data) => {
        this.loadingRawExport(false);
        downloadBlob(data, translate(this.trial.name_json) + '.xlsx');
      })
      .catch((e) => {
        this.loadingRawExport(false);
        throw e;
      });
  };

  private exportImagesToken: number;
  private imageUrlMap: KnockoutObservable<Record<string, string>> = ko.observable({});

  imagesUrl = ko.pureComputed(() => this.imageUrlMap()[this.exportImagesFilterKey()] || '');

  exportImagesFilterKey = ko.pureComputed<string>(() => JSON.stringify(this.exportImagesFilters()));

  exportImagesFilters = ko.pureComputed(() => ({
    scheduled_visit_ids: deflateList(this.scheduledVisitFilter).sort(),
    site_ids: deflateList(this.sitesFilter).sort(),
    test_subject_ids: deflateList(this.testSubjectsFilter).sort(),
    trait_ids: deflateList(this.traitsFilter).sort(),
  }));

  exportImages = () => {
    this.exportingImagesText(this.makeExportingImagesText(1));
    this.imageUrlMap({
      ...this.imageUrlMap(),
      [this.exportImagesFilterKey()]: '',
    });
    this.exportingImagesError('');

    exporterApi.exportImages(this.trial.id, this.exportImagesFilters()).then(({ task_id }) => {
      this.exportImagesToken = window.setInterval(() => this.pollExportImages(task_id), 2000);
    });
  };

  private makeExportingImagesText(perc: number) {
    return i18n.t('Preparing archive ({{perc}}%)...', { perc })();
  }

  private pollExportImages(taskId: string) {
    pollTask(taskId).then((res) => {
      if (!res) {
        // network/server error while polling
        return;
      }
      if (res.status !== 'task_done') {
        this.exportingImagesText(this.makeExportingImagesText(res.completion_perc));
        return;
      }

      this.exportingImagesText('');
      clearInterval(this.exportImagesToken);
      if (res.error_msg) {
        this.exportingImagesError(res.error_msg);
      } else {
        this.imageUrlMap({
          ...this.imageUrlMap(),
          [this.exportImagesFilterKey()]: res.download_url,
        });
      }
    });
  }
}

class AggregatedColumn {
  private id: string = null;

  mesKey: ko.Observable<string> = ko.observable('').extend({
    required: true,
    validation: {
      validator: (val: string) => {
        return val === '' || this.all().filter((mm) => mm.mesKey() === this.mesKey()).length === 1;
      },
      message: i18n.t('Already selected')(),
    },
  });
  private mesId = ko.pureComputed(() => findByKey(this.mesOptions, this.mesKey())?.id);
  aggregation = ko.observable('mean');
  useOnlyForCalculation = ko.observable(false);

  aggregationEnabled = ko.pureComputed(() => !(this.getMes()?.is_trait ?? false));

  normConfig = new NormalizationSelectConfig(
    this.mesId,
    this.mesOptions,
    makeMesOption,
    () => undefined,
    false
  );

  private subs: KnockoutSubscription[] = [];

  constructor(
    public mesOptions: MesOption[],
    private all: KnockoutObservableArray<AggregatedColumn>,
    data?: exporterApi.AggregatedColumnData
  ) {
    if (data) {
      this.id = data.id;
      this.mesKey(getKey(mesOptions, data.mm_id, data.dataset_id));
      this.aggregation(data.aggregation);
      this.useOnlyForCalculation(data.use_only_for_calculation);
      this.normConfig.init(data);
    }

    this.onMesChanged();
    this.subs.push(this.mesKey.subscribe(this.onMesChanged));
  }

  dispose() {
    this.subs.forEach((sub) => sub.dispose());
  }

  aggregationOptions = ko.pureComputed(() => {
    let mm = this.getMes();

    if (mm) {
      return getAggOptions({ isDate: mm.type === 'date', includeNone: true });
    } else {
      return [];
    }
  });

  canNormalize = ko.pureComputed(() => {
    let mes = this.getMes();
    return mes && !mes.is_date && !mes.is_trait;
  });

  private onMesChanged = () => {
    let mes = this.getMes();
    if (mes && mes.is_trait) {
      this.aggregation(mes.default_aggregation);
    }
  };

  private getMes() {
    return findByKey(this.mesOptions, this.mesKey());
  }

  toData(): exporterApi.AggregatedColumnData {
    let mes = this.getMes();

    return {
      id: this.id,
      mm_id: mes.id,
      dataset_id: mes.dataset_id,
      aggregation: this.aggregationOptions().length > 0 ? this.aggregation() : 'latest',
      use_only_for_calculation: this.useOnlyForCalculation(),
      ...this.normConfig.toData(),
    };
  }
}

function getAggOptions(params: { isDate: boolean; includeNone: boolean }) {
  let options = [
    { name: i18n.t('Mean'), value: 'mean' },
    { name: i18n.t('Min'), value: 'min' },
    { name: i18n.t('Max'), value: 'max' },
    { name: i18n.t('Latest'), value: 'latest' },
  ];

  if (!params.isDate) {
    options.splice(1, 0, { name: i18n.t('Sum'), value: 'sum' });
  }

  if (params.includeNone) {
    options.splice(0, 0, { name: i18n.t('None'), value: 'none' });
  }

  return options;
}

class CalculatedColumn {
  private id: string = null;
  private slugGenerator: SlugGenerator;

  aggError = ko.pureComputed(() => {
    let mm1 = findByKey(this.mesOptions, this.mes1Key());
    let mm2 = findByKey(this.mesOptions, this.mes2Key());
    let aggKeys = this.getAggKeys();
    let mm1Agg = aggKeys.indexOf(this.mes1Key()) >= 0;
    let mm2Agg = aggKeys.indexOf(this.mes2Key()) >= 0;

    if (mm1 && mm2 && mm1.id && mm2.id) {
      if (mm1.dataset_id !== mm2.dataset_id && (!mm1Agg || !mm2Agg)) {
        return i18n.t(
          'The two observations are from different groups, they need to be aggregated before a calculation can be applied.'
        )();
      }

      if (mm1.dataset_id === mm2.dataset_id && mm1Agg !== mm2Agg) {
        return i18n.t('The two observations have incompatible aggregations.')();
      }
    }

    return '';
  });

  title = ko.observable('').extend({
    required: true,
  });
  code = ko.observable('');
  calc = ko.observable('');
  mes1Key = ko.observable('').extend({
    required: true,
  });
  mes2Key = ko.observable('').extend({
    required: true,
  });
  aggregation = ko.observable('none');

  aggregationOptions = ko.pureComputed(() => {
    let mm1 = findByKey(this.mesOptions, this.mes1Key());
    let mm2 = findByKey(this.mesOptions, this.mes2Key());
    let aggKeys = this.getAggKeys();
    let mm1Agg = aggKeys.indexOf(this.mes1Key()) >= 0;
    let mm2Agg = aggKeys.indexOf(this.mes2Key()) >= 0;

    if (mm1 && mm2 && mm1.id && mm2.id && mm1.dataset_id === mm2.dataset_id && !mm1Agg && !mm2Agg) {
      let isDate = mm1.type === 'date' || mm2.type === 'date';
      return getAggOptions({ isDate, includeNone: false });
    } else {
      return [];
    }
  });

  private sub: KnockoutSubscription;

  constructor(
    public mesOptions: MesOption[],
    private aggregated: KnockoutObservableArray<AggregatedColumn>,
    data?: exporterApi.CalculatedColumnData
  ) {
    if (data) {
      this.id = data.id;
      this.title(data.title);
      this.code(data.code);
      this.calc(data.calc);
      this.mes1Key(getKey(mesOptions, data.mm_1_id, data.dataset_1_id));
      this.mes2Key(getKey(mesOptions, data.mm_2_id, data.dataset_2_id));
      this.aggregation(data.aggregation);
    }

    this.sub = this.aggregationOptions.subscribe((options) => {
      if (options.filter((opt) => opt.value === this.aggregation()).length === 0) {
        this.aggregation(options.length > 0 ? options[0].value : 'none');
      }
    });

    this.slugGenerator = new SlugGenerator(this.title, null, this.code, {
      canEdit: true,
      fillIfEmpty: true,
    });
  }

  dispose() {
    this.sub.dispose();
    this.slugGenerator.dispose();
  }

  private getAggKeys(): string[] {
    return this.aggregated()
      .filter((agg) => agg.aggregation() !== 'none')
      .map((agg) => agg.mesKey());
  }

  toData(): exporterApi.CalculatedColumnData {
    let mes1 = findByKey(this.mesOptions, this.mes1Key());
    let mes2 = findByKey(this.mesOptions, this.mes2Key());

    return {
      id: this.id,
      title: this.title(),
      code: this.code(),
      calc: this.calc(),
      mm_1_id: mes1.id,
      dataset_1_id: mes1.dataset_id,
      mm_2_id: mes2.id,
      dataset_2_id: mes2.dataset_id,
      aggregation: this.aggregation(),
    };
  }
}

function findByKey<K, T extends { key?: K }>(opts: T[], key: K): T {
  let idx = indexOf(opts, (opt) => opt.key === key);
  return idx === -1 ? null : opts[idx];
}

function getKey(mesOptions: MesOption[], mmId: string, datasetId: string) {
  return mesOptions.filter((opt) => opt.id === mmId && opt.dataset_id === datasetId)[0]?.key;
}

export let trialExport = {
  name: 'trial-export',
  viewModel: TrialExportScreen,
  template: template,
};

ko.components.register(trialExport.name, trialExport);
