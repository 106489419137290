import { listParamsToQueryString, request, requestRaw } from './request';
import { I18nText } from '../i18n_text';

export interface CustomExportDefinitionData {
  custom_export: CustomExportData;
  available_aggregate_mms: AvailableAggregateMM[];
}

export interface AvailableAggregateMM {
  id: string;
  name_json: I18nText;
  dataset_id: string;
  dataset_name_json: I18nText;
  type: string;
  is_surface: boolean;
  contains_management_data: boolean;
  is_trait: boolean;
  default_aggregation: string;
}

export function customExportDefinition(trialId: string): Promise<CustomExportDefinitionData> {
  return request('GET', '/api/exporter/' + trialId + '/custom_export_definition/');
}

export interface AggregatedColumnData {
  id: string;
  mm_id: string;
  dataset_id: string;
  aggregation: string;
  use_only_for_calculation: boolean;
  normalize: boolean;
  normalization_mm_id: string;
}

export interface CalculatedColumnData {
  id: string;
  title: string;
  code: string;
  calc: string;
  mm_1_id: string;
  dataset_1_id: string;
  mm_2_id: string;
  dataset_2_id: string;
  aggregation: string;
}

export type CustomExportGrouping = 'plot' | 'site';

export interface CustomExportData {
  trial_id: string;
  remember: boolean;
  aggregated: AggregatedColumnData[];
  calculated: CalculatedColumnData[];
  grouping: CustomExportGrouping;
}

export function customExport(data: CustomExportData): Promise<Blob> {
  return requestRaw('POST', '/api/exporter/custom_export/', data);
}

export function saveCustomExport(data: CustomExportData): Promise<{}> {
  return request('POST', '/api/exporter/save_custom_export/', data);
}

export function rawExport(trialId: string): Promise<Blob> {
  return requestRaw('POST', '/api/exporter/' + trialId + '/raw_export/');
}

export function exportImages(
  trialId: string,
  filters: {
    site_ids: string[];
    scheduled_visit_ids: string[];
    trait_ids: string[];
    test_subject_ids: string[];
  }
): Promise<{ task_id: string }> {
  return request(
    'POST',
    '/api/exporter/' + trialId + '/export_images/' + `?${listParamsToQueryString(filters)}`
  );
}
