import page from 'page';

import i18n from './i18n';
import { parseDate, ISO_DATE_TIME_REGEXP, parseDateTime, DATE_TIME_REGEXP } from './api/serialization';
import { listParamsToQueryString } from './api/request';
import { PortofolioItemData } from './api/portofolio_items';
import { DatasetFactSummaryData, TrialSiteSummaryData } from './api/datasets';
import { polygonContains } from 'd3-polygon';
import { uuid4 } from './utils/uuid';

export const MAX_SAFE_INTEGER = 9007199254740991;
export const KEYCODE_ENTER = 13;
export const KEYCODE_ESCAPE = 27;
export const KEYCODE_UP = 38;
export const KEYCODE_DOWN = 40;
export const KEYCODE_LEFT = 37;
export const KEYCODE_RIGHT = 39;
export const KEYCODE_TAB = 9;

export enum STORAGE_KEYS {
  OVERVIEW_CLICK_ACTION = 'overview-click-action', // User's last viewed dialog: edit or history.
  TRIAL_DASHBOARD_LAST_VISITED_TAB = 'trial-dashboard-last-visited-tab',
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }

  interface Window {
    dataLayer: any[];
  }
}

export function pad(value: number, digits: number = 3) {
  let str = value.toString();
  let prefix = '';
  for (let i = str.length; i < digits; i++) {
    prefix += '0';
  }

  return prefix + str;
}
export function timeAgo(value: any): string {
  let now = new Date();
  let dateValue: Date;
  if (value instanceof Date) {
    dateValue = value;
  } else {
    dateValue = parseDateTime(value);
  }
  let diffMs = now.getTime() - dateValue.getTime();
  let formatted: string;

  if (diffMs < 0) {
    formatted = '';
  } else {
    let secondsDiff = diffMs / 1000;
    if (secondsDiff <= 44) {
      formatted = i18n.t('a few seconds ago')();
    } else if (secondsDiff <= 89) {
      formatted = i18n.t('a minute ago')();
    } else if (secondsDiff <= 44 * 60) {
      formatted = i18n.t('{{ time }} minutes ago', {
        time: Math.round(secondsDiff / 60),
      })();
    } else if (secondsDiff <= 89 * 60) {
      formatted = i18n.t('an hour ago')();
    } else if (secondsDiff <= 21 * 60 * 60) {
      formatted = i18n.t('{{ time }} hours ago', {
        time: Math.round(secondsDiff / (60 * 60)),
      })();
    } else if (secondsDiff <= 35 * 60 * 60) {
      formatted = i18n.t('a day ago')();
    } else if (secondsDiff <= 25 * 24 * 60 * 60) {
      formatted = i18n.t('{{ time }} days ago', {
        time: Math.round(secondsDiff / (24 * 60 * 60)),
      })();
    } else if (secondsDiff <= 45 * 24 * 60 * 60) {
      formatted = i18n.t('a month ago')();
    } else if (secondsDiff <= 319 * 24 * 60 * 60) {
      formatted = i18n.t('{{ time }} months ago', {
        time: Math.round(secondsDiff / (31 * 24 * 60 * 60)),
      })();
    } else if (secondsDiff <= 547 * 24 * 60 * 60) {
      formatted = i18n.t('a year ago')();
    } else {
      formatted = i18n.t('{{ time }} years ago', {
        time: Math.round(secondsDiff / (365 * 24 * 60 * 60)),
      })();
    }
  }
  return formatted;
}

export function adjustDateToTimezoneOffset(date: Date): Date {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000)) // 60 seconds * 1000 milliseconds
}

export function tryFormatDate(date: string | Date): string {
  let parsedDate = asDate(date);

  if (parsedDate === null) {
    return <string>date;
  }

  let day = parsedDate.getDate();
  let monthIndex = parsedDate.getMonth();
  let year = parsedDate.getFullYear();

  let dateInfo = i18n.getDatePickerSettings();
  let translatedMonth = dateInfo['monthsFull'][monthIndex];

  return dateInfo.format
    .replace('yyyy', String(year))
    .replace('mmmm', translatedMonth)
    .replace('dd', String(day));
}
export function tryFormatDateShort(date: string | Date): string {
  let parsedDate = asDate(date);

  if (parsedDate === null) {
    return <string>date;
  }

  let day = parsedDate.getDate();
  let monthIndex = parsedDate.getMonth();
  let year = parsedDate.getFullYear();

  let dateInfo = i18n.getDatePickerSettings();
  let translatedMonth = dateInfo['monthsShort'][monthIndex];

  return dateInfo.format
    .replace('yyyy', String(year))
    .replace('mmmm', translatedMonth)
    .replace('dd', String(day));
}

export function tryFormatDateTime(date: string | Date): string {
  let parsedDate = asDate(date);

  if (parsedDate === null) {
    return <string>date;
  }

  let datePart = tryFormatDate(parsedDate);

  if (parsedDate && datePart) {
    return datePart + ' ' + pad(parsedDate.getHours(), 2) + ':' + pad(parsedDate.getMinutes(), 2);
  } else {
    return '';
  }
}

function asDate(date: string | Date): Date {
  if (typeof date === 'number') {
    return null;
  } else if (typeof date === 'string') {
    if (date.match(ISO_DATE_TIME_REGEXP) || date.match(DATE_TIME_REGEXP)) {
      return parseDateTime(date);
    }
    try {
      return parseDate(date);
    } catch (_) {
      return null;
    }
  } else {
    return date;
  }
}

export function isIE11() {
  return !!navigator.userAgent.match(/Trident.*rv\:11\./);
}

export function downloadBlob(blob: Blob, name: string) {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, name);
  } else {
    let url = URL.createObjectURL(blob);
    downloadURI(url, name);
    URL.revokeObjectURL(url);
  }
}

export function downloadURI(uri: string, name: string) {
  let evt = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true,
  });
  let a = document.createElement('a');
  a.setAttribute('download', name);
  a.setAttribute('href', uri);
  a.setAttribute('target', '_blank');
  a.dispatchEvent(evt);
}

const escapeElem = document.createElement('div');

export function escape(text: string): string {
  escapeElem.innerText = text;
  return escapeElem.innerHTML;
}

export function updateLocationWithQueryString(params: {}) {
  let qs = listParamsToQueryString(params);
  let newPath = qs ? location.pathname + '?' + qs : location.pathname;
  if (location.origin + newPath != location.href) {
    (<any>page).replace(newPath, null, undefined, false);
  }
}

export function sameIds(
  v1: { id?: string | KnockoutObservable<string> }[],
  v2: { id?: string | KnockoutObservable<string> }[]
): boolean {
  let v1Ids = v1.map((e) => ko.unwrap(e.id));
  let v2Ids = v2.map((e) => ko.unwrap(e.id));

  if (v1Ids.length === v2Ids.length) {
    v1Ids.sort();
    v2Ids.sort();

    for (let i = 0; i < v1Ids.length; i++) {
      if (v1Ids[i] !== v2Ids[i]) {
        return false;
      }
    }
  } else {
    return false;
  }

  return true;
}

export function sameId(v1: { id?: string }, v2: { id?: string }): boolean {
  return (!v1 && !v2) || (v1 && v2 && v1.id === v2.id);
}

export function asArray<T>(item: T | T[]): T[] {
  if (!item) {
    return [];
  }

  if (Array.isArray(item)) {
    return item;
  }

  return [item];
}

export function indexOf<T>(items: T[], pred: (item: T) => boolean): number {
  let idx = 0;
  for (let item of items) {
    if (pred(item)) {
      return idx;
    }
    idx++;
  }

  return -1;
}

export function findById<K, T extends { id?: K }>(opts: T[], id: K): T {
  let idx = indexOf(opts, (opt) => opt.id === id);
  return idx === -1 ? null : opts[idx];
}

export type BoolDict = { [key: string]: boolean };

export function range(end: number): number[] {
  let res: number[] = [];
  for (let i = 0; i < end; i++) {
    res.push(i);
  }
  return res;
}

export function readDecimal(value: number | string): string {
  if (value === null || value === undefined) {
    return '';
  }

  // strip trailing 0s
  return parseFloat(value.toString()).toString();
}

export function emptyToNull(value: string): string {
  if (value === '') {
    return null;
  }

  return value;
}

export function all(vals: boolean[]) {
  for (let val of vals) {
    if (!val) {
      return false;
    }
  }
  return true;
}

export function currentYear(): string {
  return new Date().getFullYear().toString();
}

export function extractId(value: KnockoutObservable<{ id?: string }>): string {
  return value() ? value().id : null;
}
export function extractIds(values: KnockoutObservableArray<{ id?: string }>): string[] {
  return values() ? values().map((value: { id?: string }) => value.id) : null;
}

export function refreshObservableArrayItem<T>(array: KnockoutObservableArray<T>, item: T) {
  let idx = array.indexOf(item);
  if (idx >= 0) {
    array.splice(idx, 1);
    array.splice(idx, 0, item);
  }
}

export const MONTH_OPTIONS = i18n
  .getDatePickerSettings()
  .monthsFull.map((name, idx) => ({ name, value: idx + 1 }));

export function debounce(fn: () => void, timeout: number): () => void {
  let timeoutToken: number = null;

  return () => {
    clearTimeout(timeoutToken);
    timeoutToken = window.setTimeout(() => {
      timeoutToken = null;
      fn();
    }, timeout);
  };
}

export function flatten<T>(items: T[][]): T[] {
  return Array.prototype.concat.apply([], items);
}

export function toDict<T, V>(
  items: T[],
  fn: (item: T, index?: number) => [string | number, V]
): { [key: string]: V } {
  let res: { [key: string]: V } = {};
  let index = 0;
  for (let item of items) {
    let [key, value] = fn(item, index);
    res[key] = value;
    index++;
  }

  return res;
}

export function groupBy<T, V>(
  items: T[],
  keyFn: (item: T) => string,
  fn: (item: T, acc: V) => V
): { [key: string]: V } {
  let res: { [key: string]: V } = {};
  for (let item of items) {
    let key = keyFn(item);
    res[key] = fn(item, res[key]);
  }

  return res;
}

export function accList<T>(item: T, acc: T[] | undefined): T[] {
  acc = acc || [];
  acc.push(item);
  return acc;
}

export class Counter<T> {
  private counters: { [key: string]: number } = {};

  constructor(private key: (val: T) => string) {}

  next(val: T): number {
    let key = this.key(val);
    this.counters[key] = (this.counters[key] || 0) + 1;
    return this.counters[key] - 1;
  }
}

export function getDefault<T>(dict: any, defaultValue: T, ...keys: string[]): T {
  let value = dict;
  for (let key of keys) {
    value = value[key];
    if (value === undefined) {
      return defaultValue;
    }
  }

  return value;
}

export function encodeArrayBufferToBase64(buffer: ArrayBuffer) {
  let bytes = new Uint8Array(buffer);
  let charCodes = '';
  for (let i = 0; i < bytes.length; i++) {
    charCodes += String.fromCharCode(bytes[i]);
  }
  return btoa(charCodes);
}

export class Quarter {
  year: number;
  quarter: number;

  constructor(timestamp: number) {
    let date = new Date(timestamp);
    this.year = date.getFullYear();
    this.quarter = Math.floor(date.getMonth() / 4) + 1;
  }

  max(other: Quarter | null): Quarter {
    return !other || this.gt(other) ? this : other;
  }

  min(other: Quarter | null): Quarter {
    return other && this.gt(other) ? other : this;
  }

  gt(other: Quarter): boolean {
    return this.year > other.year || (this.year === other.year && this.quarter > other.quarter);
  }

  format(): string {
    return `${this.quarter}/${this.year}`;
  }

  diff(other: Quarter): number {
    return (this.year - other.year) * 4 - other.quarter + this.quarter;
  }

  add(quarters: number): Quarter {
    let qt = new Quarter(0);
    let addQ = this.quarter + quarters - 1;
    qt.year = this.year + Math.floor(addQ / 4);
    qt.quarter = (addQ % 4) + 1;

    return qt;
  }

  getTimeStart(): number {
    return new Date(this.year, this.quarter * 4 - 4, 1, 0, 0, 0, 0).getTime();
  }

  getTimeEnd(): number {
    return this.add(1).getTimeStart() - 1;
  }
}

export function focusFirst(containerElement: Node) {
  setTimeout(() => {
    $(containerElement.parentElement).find('input[type!=checkbox]:nth(0)').not('.datepicker').focus();
  }, 0);
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), ms));
}

export function el<K extends keyof HTMLElementTagNameMap>(
  tag: K,
  className?: string
): HTMLElementTagNameMap[K] {
  let elem = document.createElement(tag);
  if (className) {
    elem.className = className;
  }

  return elem;
}

export const INTEGER_VALIDATION_RULES = {
  pattern: {
    params: '^(0|(-?[1-9][0-9]*))$',
    message: i18n.t('Invalid number')(),
  },
};

export const FLOAT_VALIDATION_RULES = {
  pattern: {
    params: /^(0|(-?[1-9][0-9]*))(\.[0-9]*)?(e(\+|-)?[0-9]+)?$/,
    message: i18n.t('Invalid number')(),
  },
};

export function escapeForRegex(value: string): string {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function readFromLocalStorage<T>(key: string) {
  let res = window.localStorage.getItem(key);
  if (!res) {
    return {} as T;
  }
  try {
    return JSON.parse(res);
  } catch {
    return {} as T;
  }
}

export const removeChildIfExists = (root: Element, element: Element) => {
  if (root.contains(element)) root.removeChild(element);
};

export const appendChildIfNotExists = (root: Element, element: Element) => {
  if (!root.contains(element)) root.appendChild(element);
};

export const fieldSorter = (fields: string[]) => {
  // Function for sorting method on arrays.
  // Can be sorted in asc & desc order
  // If desc is needed - pass field with minus sign
  // Example: arr.sort(fieldSorter(['ascField', '-descField']))
  let directions: number[] = [];
  fields = fields.map(function (field, index) {
    directions[index] = 1;
    if (field[0] === '-') {
      directions[index] = -1;
      field = field.substring(1);
    }
    return field;
  });

  return function (a: any, b: any) {
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];
      if (a[field] > b[field]) return directions[i];
      if (a[field] < b[field]) return -directions[i];
    }
    return 0;
  };
};

export const addClassInQuerySelector = (selector: string, cssClass: string) => {
  document.querySelectorAll(selector).forEach((item) => item.classList.add(cssClass));
};

export const removeClassInQuerySelector = (selector: string, cssClass: string) => {
  document.querySelectorAll(selector).forEach((item) => item.classList.remove(cssClass));
};

export const scrollToElement = (element: Element, timeout: number = 0) => {
  setTimeout(() => {
    element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }, timeout);
};

/* A function that returns distinct colors. Categorical (Qualitative) 10 colors
 */
export const getColor = (index: number) => {
  const colors = [
    '#72e5ef',
    '#33837f',
    '#71dd82',
    '#4c852e',
    '#bbcf7a',
    '#51443f',
    '#ebcecb',
    '#be3e2a',
    '#fd8f20',
    '#aa7959',
  ];
  return colors[index % colors.length];
};

export const waitForElement = (selector: string): Promise<Element> => {
  return new Promise<Element>((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export function parsePortofolioItemsNames(portofolioItems: PortofolioItemData[]): PortofolioItemData[] {
  return portofolioItems.map((portofolioItem) => ({
    ...portofolioItem,
    name: `${portofolioItem.number} - ${portofolioItem.name}`,
  }));
}

export function getFileExtension(fileName: string): string {
  return fileName.slice(fileName.lastIndexOf('.'));
}

export function getFileNameWithoutExtension(fileName: string): string {
  return fileName.slice(0, fileName.lastIndexOf('.'));
}

/**
 * Files are uploaded with naming convention `originalFileName_filenameTimestamp_UUID.extension”
 * where filenameTimeStamp = "yyyyMMdd_HHmmss", and UUID = UUID with no “-” between
 * sections in it.
 *
 * e.g. myFile.pdf -> "myFile_20210930_123456_1234567890abcdef.pdf"
 */
export function generateUploadFileName (userFileName: string): string {
    const fileNamePrefix =
      getFileNameWithoutExtension(userFileName) +
      '_' +
      new Date()
        .toISOString()
        .replace(/[^0-9T]/g, '')
        .replace('T', '_')
        .slice(0, -3) +
      '_';
    return fileNamePrefix + uuid4() + getFileExtension(userFileName);
}


export function retrieveNumberOfOffSiteObservations(
  site: TrialSiteSummaryData,
  facts: DatasetFactSummaryData[]
) {
  let siteShapePolygon: any = [];
  let offSiteObservationsNumber: number = 0;
  if (
    Array.isArray(site.site_area.geometry.coordinates[0]) &&
    Array.isArray(site.site_area.geometry.coordinates[0][0])
  ) {
    const coordinates: any = site.site_area.geometry.coordinates[0];
    siteShapePolygon = coordinates.map((value: number[]) => [value[1], value[0]]);
    const factPoints: [number, number][] = facts.map((fact) => [fact.location_lat, fact.location_lon]);
    for (let factPoint of factPoints) {
      if (!polygonContains(siteShapePolygon, factPoint)) {
        offSiteObservationsNumber += 1;
      }
    }
  }

  return offSiteObservationsNumber;
}

export function retrieveOnSiteAndOffSitePoints(site: TrialSiteSummaryData | undefined, facts: DatasetFactSummaryData[]) {
  let siteShapePolygon: any = [];
  let offSiteObservations: DatasetFactSummaryData[] = [];
  let onSiteObservations: DatasetFactSummaryData[] = [];
  if (
    site && site.site_area &&
    Array.isArray(site.site_area.geometry.coordinates[0]) &&
    Array.isArray(site.site_area.geometry.coordinates[0][0])
  ) {
    const coordinates: any = site.site_area.geometry.coordinates[0];
    siteShapePolygon = coordinates.map((value: number[]) => [value[1], value[0]]);
    for (let fact of facts) {
      const factPoint: [number, number] = [fact.location_lat, fact.location_lon];
      if (polygonContains(siteShapePolygon, factPoint)) {
        onSiteObservations.push(fact);
      } else {
        offSiteObservations.push(fact);
      }
    }
  } else {
    for (let fact of facts) {
        onSiteObservations.push(fact);
    }
  }

  return [offSiteObservations, onSiteObservations];
}

export function trimObjectValues(obj: any) {
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    }
  }
  return obj;
}
